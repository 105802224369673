<template>
  <div class="positionDetails">
    <div class="title">{{list.title}}</div>
    <div class="time">{{list.createTime}}</div>
    <div class="content">{{list.content}}</div>
  </div>
</template>

<script>
export default {
  name: '',
  props:{},
  data () {
    return {
      id:"",
      list:""
    }
  },
  components: {},
  methods: {
    getpositionInfo(){
      this.$api.getpositionInfo({id:this.id}).then((res) => {
          if (res.code === 0) {
            this.list=res.data
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  mounted() {
    this.id=this.$route.query.id
    if(this.id!==""){
      this.getpositionInfo()
    }
  },
  computed: {},
  watch: {}
}
</script>

<style lang='scss' scoped>
.positionDetails{
  width: 100%;
  height: 100%;
  padding: 6.25rem;
  .title{
    text-align: center;
    font-size: 2.1875rem;
    font-weight: 700;
  }
  .time{
    margin: 1.25rem 0;
    text-align: center;
    font-size: 1.25rem;
  }
  .content{
    white-space: pre-line;
    font-size: 1rem;
  }
}
</style>